/* eslint-disable no-secrets/no-secrets */
import React from 'react';
import styled from '@emotion/styled';
import EmailIcon from '@assets/svgs/email.svg';
import PhoneIcon from '@assets/svgs/phone.svg';
import LocationIcon from '@assets/svgs/location-pin.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import CallToAction from '~/components/atoms/CallToAction/CallToAction';
import { useMobile } from '~/utils/hook-functions';

type Props = {};

const MobileCTAHeader: React.FC<Props> = () => {
  const mobile = useMobile();
  const { i18n } = useTranslation();

  return (
    <>
      {mobile ? (
        <Container>
          <div style={{ display: 'flex' }}>
            <CallToAction
              backgroundColor="var(--mercury-color)"
              width="27%"
              text={i18n.language === 'de' ? 'Standort' : 'Location'}
              href="https://www.google.com/maps/place/Neurozentrum+Arabellapark/@48.1519591,11.6171166,17z/data=!4m8!1m2!2m1!1sneurozentrum+arabellapark!3m4!1s0x479e753848617349:0x2eb4f39f195be657!8m2!3d48.1519591!4d11.6193053"
            >
              <LocationIcon width="24" height="24" />
            </CallToAction>
            <CallToAction
              backgroundColor="var(--mercury-color)"
              width="38%"
              text={i18n.language === 'de' ? 'E-Mail' : 'Email'}
              href="mailto:info@neurozentrum-arabellapark.de"
            >
              <EmailIcon width="24" height="24" />
            </CallToAction>
            <CallToAction
              backgroundColor="var(--mercury-color)"
              width="35%"
              text="089/95006766"
              href="tel:+498995006766"
            >
              <PhoneIcon width="24" height="24" />
            </CallToAction>
          </div>
        </Container>
      ) : null}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20000;
`;

export default MobileCTAHeader;
