import React from 'react';
import styled from '@emotion/styled';

type Props = {
  backgroundColor: string;
  text: string;
  href: string;
  reverse?: boolean;
  width: string;
};

const CallToAction: React.FC<Props> = ({
  children,
  backgroundColor,
  text,
  href,
  reverse,
  width,
}) => {
  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '50px',
          width,
          backgroundColor,
        }}
      >
        <Container background={backgroundColor}>
          <a href={href}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {reverse ? (
                <>
                  <span style={{ paddingRight: '0.5rem' }}>{text}</span>
                  {children}
                </>
              ) : (
                <>
                  {children}
                  <span style={{ paddingLeft: '0.5rem' }}>{text}</span>
                </>
              )}
            </span>
          </a>
        </Container>
      </div>
    </>
  );
};

const Container = styled.div`
  font-size: var(--primary-font-size);
  position: absolute;
  word-break: normal;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default CallToAction;
