import React from 'react';
import styled from '@emotion/styled';
import ClockIcon from '@assets/svgs/clock.svg';
import PhoneIcon from '@assets/svgs/phone.svg';
import EmailIcon from '@assets/svgs/email.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMobile } from '~/utils/hook-functions';
import CallToAction from '~/components/atoms/CallToAction/CallToAction';

type Props = {};

const MobileCTAFooter: React.FC<Props> = () => {
  const mobile = useMobile();
  const { i18n } = useTranslation();

  return (
    <>
      {mobile ? (
        <Container>
          <div style={{ display: 'flex' }}>
            <CallToAction
              backgroundColor="var(--primary-color)"
              width="33%"
              text={i18n.language === 'de' ? 'Termin' : 'Appointm.'}
              href="https://www.neurozentrum-arabellapark.de/terminvereinbarung/"
              reverse
            >
              <ClockIcon width="24" height="24" />
            </CallToAction>
            <CallToAction
              backgroundColor="var(--primary-color)"
              width="33%"
              text={i18n.language === 'de' ? 'Anruf' : 'Call'}
              href="tel:+498995006766"
              reverse
            >
              <PhoneIcon width="24" height="24" />
            </CallToAction>
            <CallToAction
              backgroundColor="var(--primary-color)"
              width="33%"
              text={i18n.language === 'de' ? 'Kontakt' : 'Contact'}
              href="https://www.neurozentrum-arabellapark.de/kontakt/"
              reverse
            >
              <EmailIcon width="24" height="24" />
            </CallToAction>
          </div>
        </Container>
      ) : null}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 20000;
`;

export default MobileCTAFooter;
